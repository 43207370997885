import React from "react"
import {
  PageTitle,
  InsideWrapper,
  pushGTMEvent,
  GTMConvertProduct,
} from "../../components/globals"
import {
  ProductWrapper,
  ProductSection,
  ProductCard,
  ProductImage,
  CardContent,
} from "./style"

const ProductListPage = ({ data, context }) => {
  React.useEffect(() => {
    if (context?.products) {
      pushGTMEvent(
        "view_item_list",
        context?.products?.map(({ node }) => GTMConvertProduct(node))
      )
    }
  }, [context])
  return (
    <InsideWrapper>
      <PageTitle>Disse linsene har vi på lager i butikken</PageTitle>
      <ProductWrapper>
        {context?.products?.map(({ node: product }) => {
          const image = product?.images?.[1]?.localFile?.childImageSharp?.gatsbyImageData

          return (
            <ProductSection key={`product-${product.strapi_id}`}>
              <ProductCard
                to={`/produkt/${product.strapi_id}/`}
                onClick={() => {
                  pushGTMEvent("select_item", [GTMConvertProduct(product)])
                }}
              >
                <ProductImage
                  image={image}
                  objectFit
                  imgStyle={{
                    objectFit: "contain",
                  }}
                />
                <CardContent>
                  <h3>{product.title}</h3>
                  <h4>{product.price},- NOK</h4>
                  <h5>{product.packagesize}</h5>
                  <button>Se mer</button>
                </CardContent>
              </ProductCard>
            </ProductSection>
          )
        })}
      </ProductWrapper>
    </InsideWrapper>
  );
}
export default ProductListPage
