import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby"

export const ProductWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
`
export const ProductSection = styled.div`
  flex-basis: 25%;
  padding: 1em;
  @media screen and (max-width: 991px) {
    flex-basis: 100%;
  }
`
export const ProductCard = styled(Link)`
  text-decoration: none;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  background: white;
  height: 400px;
  display: flex;
  flex-direction: column;
  box-shadow: none;
  transition: box-shadow 0.3s ease;
  &:hover {
    box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.02),
      0 6.7px 5.3px rgba(0, 0, 0, 0.028), 0 12.5px 10px rgba(0, 0, 0, 0.035),
      0 22.3px 17.9px rgba(0, 0, 0, 0.042), 0 41.8px 33.4px rgba(0, 0, 0, 0.05),
      0 100px 80px rgba(0, 0, 0, 0.07);
  }
`
export const ProductImage = styled(GatsbyImage)`
  height: 300px;
  width: 100%;
`
export const CardContent = styled.div`
  padding: 1em 2em;
  flex-grow: 1;

  & h3 {
    color: #0b0c14;
    margin-bottom: 0.25em;
  }
  & h4 {
    color: #9ac0e3;
    font-size: 1.25em;
    font-weight: 600;
  }
  & h5 {
    color: white;
    background: #0b0c14;
    padding: 0.5em;
    border-radius: 50%;
    font-size: 1.25em;
    top: 1em;
    right: 1em;
    position: absolute;
  }
  & button {
    border: 0;
    position: absolute;
    bottom: 0;
    right: 0;
    background: #9ac0e3;
    border-radius: 10px 0 10px 0;
    padding: 0.5em 0;
    cursor: pointer;
    width: 33%;
  }
`
