import React from "react"
import Layout from "../components/layout"
import ProductListPageContent from "../pageContent/listProductPage"

const ProductListPage = ({ data, pageContext }) => {
  return (
    <Layout
      title="Linsepakker til salgs"
      description="Linsepakker til salgs hos Lambertseter Ur & Optikk. Klikk og bestill"
      keywords={["linser", "bestillinger", "online", "raskt", "enkelt"]}
    >
      <ProductListPageContent data={data} context={pageContext} />
    </Layout>
  )
}
export default ProductListPage
